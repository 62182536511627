/* eslint-disable no-param-reassign */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { selectProgram } from '@/utilities';

import store from '../store';
import { ConfigState, PartnerConfig, RootState } from '../types';

const initialState = (): ConfigState => ({
  partnerConfig: <Record<string, PartnerConfig>>{}
});

const state = initialState();

const getters: GetterTree<ConfigState, RootState> = {
  getPartnerConfig: (state) => (slug: string) => state.partnerConfig[slug] || state.partnerConfig['default'],
  labels: (state, getters) => {
    const programs = store.getters['user/getPrograms'] || [];
    const program = selectProgram(programs);
    const programName = program?.name;
    const partnerConfig =
      programName in state.partnerConfig ? state.partnerConfig[programName] : state.partnerConfig['default'];
    return getters.labelsFromConfig(partnerConfig);
  },
  labelsFromConfig: (state) => (partnerConfig: PartnerConfig | undefined) => {
    const config = partnerConfig ?? state.partnerConfig['default'];
    const contactPhone = config.strings.memberServicesPhone;

    return {
      openbay: config.whiteLabel ? config.strings.plusBranding : 'Openbay',
      openbayPlus: config.whiteLabel ? config.strings.plusBranding : 'Openbay+',
      articlePlus: config.whiteLabel ? config.strings.articlePlusBranding : 'an Openbay+',
      memberServices: config.whiteLabel ? config.strings.memberServices : 'memberservices@openbay.com',
      contactPhone: config.whiteLabel && contactPhone ? contactPhone.number : '+1-617-398-8888',
      contactPhoneFormatted: config.whiteLabel && contactPhone ? contactPhone.formatted : '(617) 398-8888',
      termsAndConditions: config.whiteLabel ? config.strings.termsAndConditions : '/tos',
      privacyPolicy: config.whiteLabel ? config.strings.privacyPolicy : '/privacy-policy',
      copyrightNotice: config.strings.copyrightNotice,
    };
  }
};

const actions: ActionTree<ConfigState, RootState> = {
  fetchPartnerConfig({ commit }: any) {
    fetch('/assets/partners/config.json').then(async (response) => {
      const config = await response.json();
      commit('setPartnerConfig', config);
    });
  },
  reset({ commit }: any) {
    commit('reset');
  }
};

const mutations: MutationTree<ConfigState> = {
  setPartnerConfig(state, partnerConfig: Record<string, PartnerConfig>) {
    state.partnerConfig = partnerConfig;
  },
  reset(state) {
    Object.assign(state, initialState());
  }
};

const ConfigStore: Module<ConfigState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default ConfigStore;
